import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import NewSIFormProperties from './NewSIFormProperties'
import Utils from 'src/views/freightbooking/shared/Utils'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import CommonUtils from 'src/views/common/utils/Utils'
import useNewSIMakeValidationSchema from './useNewSIMakeValidationSchema'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function NewSIPage({ showNotification, prevMenu }) {
  const history = useHistory()

  const { createRecord } = useServices()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = ShippingInstructionTranslationText()

  const validationSchema = useNewSIMakeValidationSchema()
  const [recalculate, setRecalculate] = useState(0) //for dg recalculate total value, in the case there is error message, the field value (calculated) cannot be kept in the field, hence need to recalculate

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('submit', data)
    //const siSubmit = JSON.parse(JSON.stringify(data))
    SIUtils.convertDate(data)

    if (data.hazardous) {
      if (data.shippingInfoDGCargoes && data.shippingInfoDGCargoes.length > 0) {
        data.shippingInfoDGCargoes.forEach((dgCargo) => {
          if (dgCargo.dgDetails != null) {
            dgCargo.dgDetails.forEach((cargoDetails) => {
              console.log(
                'cargoDetails.dateOfFumigation',
                cargoDetails.dateOfFumigation
              )
              cargoDetails.dateOfFumigation =
                CommonUtils.strictFormatDateAndConvertToGMT(
                  cargoDetails.dateOfFumigation,
                  CommonUtils.UI_FORMAT_DATE_TIME,
                  ''
                )
            })
          }
        })
      }
    }

    if (data['saveDraft']) {
      //if there is document, save document first then get the doc id and save draft
      console.log(data.pendingShippingInfoDocuments)
      if (
        data.pendingShippingInfoDocuments &&
        !Utils.isEmpty(data.pendingShippingInfoDocuments)
      ) {
        uploadDocumentAndSaveDraft(data)
      } else {
        saveDraft(data)
      }
    } else {
      //go to step 2 page
      //for dg cargo, somehow the calculated total no. cannot be submitted as part of field value, hence need to redo the calcualtion before proceed to next page
      if (data.hazardous && data.shippingInfoDGCargoes) {
        let newCargo = SIUtils.calculateTotalWeightVolume(
          data.shippingInfoDGCargoes
        )
      }

      //pre save check
      createReviewSI(data)
    }
  }

  function uploadDocumentAndSaveDraft(data) {
    setLoading(true)
    console.log('uploadDocumentAndSaveDraft')

    var formData = new FormData()
    console.log(data.pendingShippingInfoDocuments)

    data.pendingShippingInfoDocuments.map((file, i) => {
      var fileBlob = file.file
      console.log(fileBlob)
      formData.append('fileContents', fileBlob) // name of the file upload box
      return null
    })

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      if (response.errorMessages != null) {
        setRecalculate(recalculate + 1)
        showErrorNotification(response.errorMessages)
      } else {
        let siDocs = data.shippingInfoDocuments || [] //to store the final  documents
        if (response && response.length > 0) {
          response.forEach((file, i) => {
            let siDoc = {
              docType: data.pendingShippingInfoDocuments[i].fields[0].value,
              filename: file.fileName,
              description: data.pendingShippingInfoDocuments[i].fields[1].value,
              docId: file.id,
              filesize: file.fileSize
            }

            siDocs.push(siDoc)
          })
        }

        data.shippingInfoDocuments = siDocs

        console.log(data)
        saveDraft(data)
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.DOCUMENT_UPLOAD,
      formData,
      onSuccess,
      onError
    )
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    setLoading(false)
    setRecalculate(recalculate + 1)
    showErrorNotification(CommonUtils.getErrorMessage(error))
  }

  function saveDraft(data) {
    setLoading(true)
    console.log('saveDraft')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages != null) {
        setRecalculate(recalculate + 1)
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(
          `${translatedTextsObject.draftSuccessMsg} ${response.bookingRefNo} `
        )

        redirectToSIList()
      }
    }

    createRecord.execute(
      prevMenu !== SIUtils.Menus.mySI
        ? ShippingInstructionApiUrls.SAVE_DRAFT
        : ShippingInstructionApiUrls.SHIPPER_UPDATE_SI,
      data,
      onSuccess,
      onError
    )
  }

  function createReviewSI(data) {
    console.log(data)

    setLoading(true)
    console.log('reviewBooking')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages != null) {
        setRecalculate(recalculate + 1)
        showErrorNotification(response.errorMessages)
        // }
      } else {
        redirectToReviewPage(data)
      }
    }

    function onError(error) {
      console.log('connection-request error', error.message)
      setLoading(false)
      setRecalculate(recalculate + 1)
      showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    createRecord.execute(
      ShippingInstructionApiUrls.REVIEW_SI,
      data,
      onSuccess,
      onError
    )
  }

  function redirectToSIList() {
    if (prevMenu !== SIUtils.Menus.mySI) {
      history.push({
        pathname: pathMap.CREATE_SI,
        state: { shipmentClicked: false } //redirect to create si > draft search
      })
    } else {
      history.push({
        pathname: pathMap.MY_SI //redirect to my shipping instruction
      })
    }
  }

  function redirectToReviewPage(data) {
    console.log('redirectToReviewPage', data)
    console.log('prevMenu', prevMenu)
    switch (prevMenu) {
      case SIUtils.Menus.newSI:
        history.push({
          pathname: pathMap.CREATE_SI_NEW_SI_REVIEW,
          state: { data, prevMenu }
        })
        break

      case SIUtils.Menus.mySI:
        history.push({
          pathname: pathMap.MY_SI_MANAGE_SI_REVIEW,
          state: { data, prevMenu }
        })
        break

      case SIUtils.Menus.draftSI:
        history.push({
          pathname: pathMap.CREATE_SI_DRAFT_REVIEW,
          state: { data, prevMenu }
        })
        break

      default:
        break
    }
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <NewSIFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          loading={loading}
          prevMenu={prevMenu}
          setLoading={setLoading}
          recalculate={recalculate}
          onSubmitForm={onSubmit}
          validationSchema={validationSchema}
        />
      }
      formikProps={{
        ...NewSIFormProperties.formikProps,
        validationSchema,
      }}
    />
  )
}

export default NewSIPage
