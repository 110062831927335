import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ViewBookingTypeComponent from 'src/views/freightbooking/components/ViewBookingTypeComponent'
import ViewScheduleComponent from 'src/views/freightbooking/components/ViewScheduleComponent'
import ViewSIPageFormProperties from './ViewSIPageFormProperties'

import SIApi from 'src/views/shippinginstruction/shared/api'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import * as calistaConstant from 'src/constants/CalistaConstant'

import {
  useServices,
  constants
} from 'cng-web-lib'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
const { CodeMaintenanceType } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...ViewBookingTypeComponent.initialValues,
  ...ViewScheduleComponent.initialValues
})
const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields(props) {
  const location = useLocation()
  const { fetchRecords } = useServices()
  const [auditList, setAuditList] = useState([])
  const [userCriteria, setUserCriteria] = useState([])
  const [bookingAndSI, setBookingAndSI] = useState({})
  const [ports, setPorts] = useState([])
  const [countries, setCountries] = useState([])
  const [blApplicationType, setBlApplicationType] = useState([])
  console.log(location.state)
  const siRequest = {
    shippingInfoId: location.state.shippingInfoId,
    freightBookingId: location.state.freightBookingId
  }
  useEffect(() => {
    SIApi.fetchSIDetailsById(fetchRecords, siRequest, populateBookingAndSIData, props.helpdesk)

    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.PORT,
      [],
      false,
      onLoadPortSuccess
    )

    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      calistaConstant.CAL_BL_TYPE,
      false,
      onLoadCodeMasterSuccess
    )


    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      onLoadCountrySuccess
    )
  }, [])

  function onLoadCountrySuccess(data) {
    console.log('onLoadCountrySuccess', data)
    setCountries(data)
  }

  function populateBookingAndSIData(data) {
    //data.bookStatusDesc = location.state.bookStatusDesc

    setBookingAndSI(SIUtils.expandBookingToShippingInfo(data))
    if (data.shippingInfoId) {
      SIApi.fetchAuditTrailListing(
        fetchRecords,
        data.shippingInfoId,
        onAuditTrailListingLoadSuccess
      )
    }
  }
  function onAuditTrailListingLoadSuccess(data) {
    console.log('onAuditTrailListingLoadSuccess', data)
    data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
    setAuditList(data)

    let userList = []
    data.forEach((val) => {
      userList.push(val.userId)
    })
    setUserCriteria(userList)
  }


  function onLoadCodeMasterSuccess(data) {
    console.log('onLoadCodeMasterSuccess', data)
    setBlApplicationType(data)
  }

  function onLoadPortSuccess(data) {
    console.log('onLoadPortSuccess', data)
    setPorts(data)
  }
  return (
    <div>
      <ViewSIPageFormProperties.FormFields
        shippingInfo={bookingAndSI}
        ports={ports}
        countries={countries}
        auditList={auditList}
        userCriteria={userCriteria}
        blApplicationType={blApplicationType}
        isCarrier={location.state.isCarrier}
        showNotification={props.showNotification}
        {...props}
      />
    </div>
  )
}

const BodySection = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default BodySection
